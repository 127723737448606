import React, { Fragment, useEffect, useState } from 'react';
import { Header } from '../../Components/Layout/Layout';
import { checkError, getDate, restRequest } from '../../Helpers';
import { Field, FormAction } from '../../Components/Form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Loader, PDFViewer } from '../../Components';
import useMobileDetection from '../../Hooks/MobileDetect';
import './pdf.css';
import { Form, Formik } from 'formik';

export const PdfTemplates = (props) => {
  const history = useHistory();
  const [pdf, setpdf] = useState();
  const [hasMobile] = useMobileDetection();
  const [showPDF, setShowPDF] = useState(false);
  const [showData, setShowData] = useState(false);
  const [dropdownvalue, setdropdownvalue] = useState(null);
  const [loader, setLoader] = useState(false);

  const [handleBrowseData, setHandleBrowseData] = useState({
    all_templates: [],
    organization_templates: [],
  });
  const [Handleolddata, setHandleolddata] = useState({
    all_templates: [],
  });
  const [HandleNewdata, setHandleNewdata] = useState({
    all_templates: [],
  });
  const [selectedTemplateId, setSelectedTemplateId] = useState({
    new_template_id: null,
    old_template_id: null,
    all_template_id: null,
  });
  const closePDF = () => setShowPDF(!showPDF);

  const allDatta = [
    {
      id: 1,
      value: 'Same for all invoices',
      label: 'Same Design for Old & New Printouts',
    },
    {
      id: 2,
      value: 'different for old and new',
      label: 'Different Designs for Old & New Printouts',
    },
  ];
  useEffect(() => {
    setLoader(true);
    restRequest('get', `preferences/pdftemplates`)
      .then((res) => {
        setHandleBrowseData(res);
        setHandleolddata({ all_templates: res.all_templates });
        setHandleNewdata({ all_templates: res.all_templates });
        const filteredTemplates = res.organization_templates.filter(
          (template) =>
            template.type === 'all' ||
            template.type === 'new' ||
            template.type === 'old'
        );
        const defaultType = filteredTemplates.find(
          (template) => template.type === 'all'
        );
        let data = allDatta.find((option) => {
          return option.id === 1;
        });
        if (defaultType) {
          let newTemplateIds = filteredTemplates.reduce((acc, template) => {
            if (template.type === 'all') {
              if (template.type === 'all' && template.is_active == 1) {
                setdropdownvalue(1);
              } else {
                setdropdownvalue(2);
              }
              // If "all", set dropdown to "Same for all invoices"
              acc.all_template_id = template.general_pdf_template_id;
            } else if (template.type === 'new') {
              acc.new_template_id = template.general_pdf_template_id;
            } else if (template.type === 'old') {
              acc.old_template_id = template.general_pdf_template_id;
            }
            return acc;
          }, {});
          setSelectedTemplateId((prevState) => ({
            ...prevState,
            ...newTemplateIds,
          }));
        }
        //  else {
        //   setdropdownvalue(2);
        // }
        setShowData(true);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  }, []);

  const openPdf = (data) => {
    if (hasMobile) {
      window.open(`sample-pdf/${data.slug}`, '_blank');
    } else {
      setpdf(null);
      setShowPDF(true);
      restRequest('get', `sample-pdf/${data.slug}`)
        .then((res) => {
          setpdf(res);
        })
        .catch((error) => checkError(error, props.handleToast));
    }
  };
  const handleSubmit = (data) => {
    setLoader(true);

    restRequest('put', `preferences/pdftemplates`, {
      status:
        dropdownvalue == 1
          ? { all: true, new: false, old: false }
          : { all: false, new: true, old: true },
      new_template_id:
        typeof selectedTemplateId?.new_template_id === 'object'
          ? selectedTemplateId?.new_template_id[0]
          : selectedTemplateId?.new_template_id,
      old_template_id:
        typeof selectedTemplateId?.old_template_id === 'object'
          ? selectedTemplateId?.old_template_id[0]
          : selectedTemplateId?.old_template_id,
      all_template_id:
        typeof selectedTemplateId?.all_template_id === 'object'
          ? selectedTemplateId?.all_template_id[0]
          : selectedTemplateId?.all_template_id,
    })
      .then((res) => {
        props.handleToast('Pdf template updated successfully.', 'success');
        // history.goBack();
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);

        props.handleToast(error, 'error');
      });
  };
  const handleTemplateClick = (templateId, type) => {
    const id = Array.isArray(templateId) ? templateId[0] : templateId;
    const selectedTemplateIdOldData = Array.isArray(
      selectedTemplateId.old_template_id
    )
      ? selectedTemplateId.old_template_id[0]
      : selectedTemplateId.old_template_id;
    const selectedTemplateIdNewData = Array.isArray(
      selectedTemplateId.new_template_id
    )
      ? selectedTemplateId.new_template_id[0]
      : selectedTemplateId.new_template_id;
    const selectedTemplateIdAllData = Array.isArray(
      selectedTemplateId.all_template_id
    )
      ? selectedTemplateId.all_template_id[0]
      : selectedTemplateId.all_template_id;

    const updatedSelectedTemplate = {
      all_template_id: type == 'all' ? id : selectedTemplateIdAllData,
      old_template_id: type == 'old' ? id : selectedTemplateIdOldData,
      new_template_id: type == 'new' ? id : selectedTemplateIdNewData,
    };

    setSelectedTemplateId(updatedSelectedTemplate);
  };

  return (
    <>
      {!loader ? (
        <Fragment>
          <Header title="PDF Templates" />
          <div className="" style={{ marginLeft: '20px' }}>
            <Formik
              enableReinitialize
              initialValues={{ organizationType: dropdownvalue }} // Initialize form values
              onSubmit={handleSubmit} // Handle form submission
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <h1>
                    How you want to Configure Printout Designs?
                    <span className="time-span">
                      (only for invoices sections)
                    </span>
                  </h1>
                  <Field
                    size="large"
                    id="organizationType"
                    className="field-pdf--dropdown"
                    type="dropdown"
                    // className="reg_business_type_field "
                    // name="Select only for invoice sections"
                    placeholder="Business Type"
                    value={values.organizationType}
                    options={allDatta}
                    dropdownValue="id"
                    dropdownLabel="label"
                    clearable={false}
                    handleChange={(option) => {
                      if (option.id == 2) {
                        let filteredTemplates =
                          handleBrowseData.organization_templates.filter(
                            (template) =>
                              template.type === 'old' || template.type === 'new'
                          );
                        let oldTemplateIds = filteredTemplates
                          .filter((template) => template.type === 'old')
                          .map((template) => template.general_pdf_template_id);
                        let newTemplateIds = filteredTemplates
                          .filter((template) => template.type === 'new')
                          .map((template) => template.general_pdf_template_id);

                        setSelectedTemplateId((prevState) => ({
                          ...prevState,
                          old_template_id: oldTemplateIds,
                          new_template_id: newTemplateIds,
                        }));

                        // Keep `handleBrowseData.all_templates` intact
                        setHandleBrowseData({
                          ...handleBrowseData,
                          all_templates: [...handleBrowseData.all_templates],
                        });
                        setFieldValue(
                          'organizationType',
                          option ? option.value : null
                        );
                        setdropdownvalue(2);
                      } else if (option.id == 1) {
                        setFieldValue(
                          'organizationType',
                          option ? option.value : null
                        );
                        setdropdownvalue(1);
                      }
                    }}
                  />
                </Form>
              )}
            </Formik>

            <h1>
              {dropdownvalue == 1 ? 'Printout Designs For All Data' : null}
            </h1>
            <div
              className="card_container styled--store"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {handleBrowseData.all_templates?.length > 0 &&
              dropdownvalue == 1 ? (
                handleBrowseData.all_templates.map((data, index) => (
                  <div
                    className="temp"
                    onClick={() => handleTemplateClick(data.id, 'all')}
                    style={{
                      border:
                        selectedTemplateId.all_template_id == data.id
                          ? '1px solid #1170ff'
                          : '',
                    }}
                    key={index}
                  >
                    {selectedTemplateId.all_template_id == data.id && (
                      <span className="selected btn"> selected</span>
                    )}
                    <div className="img_container">
                      <img
                        alt={data?.name}
                        src={data?.image_url}
                        style={{ maxWidth: '170px' }}
                      />
                      <div className="default_badge">
                        {data?.is_default == true && (
                          <span
                            style={{ marginLeft: '39px' }}
                            className="status status--draft"
                          >
                            Default
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="text_area">
                      <button
                        type="submit"
                        id="click_login_button"
                        className="button--primary ml-15"
                        onClick={(e) => {
                          e.stopPropagation();
                          openPdf(data);
                        }}
                      >
                        Preview Template
                      </button>
                    </div>
                    <label>{data?.name}</label>
                  </div>
                ))
              ) : (
                <p>{dropdownvalue == 1 && 'No Templates available.'}</p>
              )}
            </div>
            <h1>
              {dropdownvalue == 2 && (
                <>
                  Printout Designs For Old Data
                  <span className="time-span">
                    (selected design will be used for printouts of data created
                    before{' '}
                    {getDate(
                      handleBrowseData.organization_templates[2]?.active_from
                    )}
                    )
                  </span>
                </>
              )}
            </h1>
            <div
              className="card_container styled--store"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {Handleolddata.all_templates?.length > 0 && dropdownvalue == 2 ? (
                Handleolddata.all_templates.map((data, index) => (
                  <div
                    className="temp"
                    onClick={() => handleTemplateClick(data.id, 'old')}
                    style={{
                      border:
                        selectedTemplateId.old_template_id == data.id
                          ? '1px solid #1170ff'
                          : '',
                    }}
                    key={index}
                  >
                    {selectedTemplateId.old_template_id == data.id && (
                      <span className="selected btn"> selected</span>
                    )}
                    <div className="img_container">
                      <img
                        alt={data?.name}
                        src={data?.image_url}
                        style={{ maxWidth: '170px' }}
                      />
                      <div className="default_badge">
                        {data?.is_default == true && (
                          <span
                            style={{ marginLeft: '39px' }}
                            className="status status--draft"
                          >
                            Default
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="text_area">
                      <button
                        type="submit"
                        id="click_login_button"
                        className="button--primary ml-15"
                        onClick={(e) => {
                          e.stopPropagation();
                          openPdf(data);
                        }}
                      >
                        Preview Template
                      </button>
                    </div>
                    <label>{data?.name}</label>
                  </div>
                ))
              ) : (
                <p>{dropdownvalue == 2 && 'No Templates available.'}</p>
              )}
            </div>
            <h1>
              {dropdownvalue == 2 && (
                <>
                  Printout Designs For New Data
                  <span className="time-span">
                    (selected design will be used for printouts of data created
                    from{' '}
                    {getDate(
                      handleBrowseData.organization_templates[2]?.active_from
                    )}
                    )
                  </span>
                </>
              )}
            </h1>
            <div
              className="card_container styled--store"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              {HandleNewdata.all_templates?.length > 0 && dropdownvalue == 2 ? (
                HandleNewdata.all_templates.map((data, index) => (
                  <div
                    className="temp"
                    onClick={() => handleTemplateClick(data.id, 'new')}
                    style={{
                      border:
                        selectedTemplateId.new_template_id == data.id
                          ? '1px solid #1170ff'
                          : '',
                    }}
                    key={index}
                  >
                    {selectedTemplateId.new_template_id == data.id && (
                      <span className="selected btn"> selected</span>
                    )}
                    <div className="img_container">
                      <img
                        alt={data?.name}
                        src={data?.image_url}
                        style={{ maxWidth: '170px' }}
                      />
                      <div className="default_badge">
                        {data?.is_default == true && (
                          <span
                            style={{ marginLeft: '39px' }}
                            className="status status--draft"
                          >
                            Default
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="text_area">
                      <button
                        type="submit"
                        id="click_login_button"
                        className="button--primary ml-15"
                        onClick={(e) => {
                          e.stopPropagation();
                          openPdf(data);
                        }}
                      >
                        Preview Template
                      </button>
                    </div>
                    <label>{data?.name}</label>
                  </div>
                ))
              ) : (
                <p>{dropdownvalue == 2 && 'No Templates available.'}</p>
              )}
            </div>
          </div>

          <div
            className="float-left w-100"
            style={{ borderTop: '1px solid var(--border-light)' }}
          >
            <FormAction
              submitLabel="Update"
              disabled={!showData}
              onSubmit={handleSubmit}
              onCancel={() => history.goBack()}
            />
          </div>
          <PDFViewer showPDF={showPDF} hidePDFModal={closePDF} pdf={pdf} />
        </Fragment>
      ) : (
        <Loader />
      )}
    </>
  );
};
